<template>
  <el-dialog :title="$t('UserProfile.Crop photo')" :visible="visible" :close-on-click-modal="false" width="350px" @update:visible="$emit('update:visible', $event)">
    <vue-cropper
      v-if="imgSrc"
      ref="cropper"
      guides
      :view-mode="2"
      :auto-crop-area="0.5"
      :min-container-width="200"
      :min-container-height="200"
      background
      :rotatable="false"
      :src="imgSrc"
      :img-style="{ 'width': '200px', 'height': '200px' }"
      :aspect-ratio="1"
      drag-mode="crop"
    />
    <br>
    <div style="display: flex;justify-content: flex-end">
      <tw-button type="primary" @click="cropImage">{{$t('Button.OK')}}</tw-button>
    </div>
  </el-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import TwButton from '@/components/atoms/TwButton';

export default {
  components: {
    VueCropper,
    TwButton
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    imgSrc: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    cropImage() {
      this.$store.commit('START_PROCESS');
      const newIcon = this.$refs.cropper.getCroppedCanvas({width: 500}).toDataURL('image/jpeg');
      this.$emit('changeIcon', newIcon);
    }
  }
}
</script>
