<template>
  <div ref="passwordIndicator" id="password-indicator">
    <div class="gauge"></div>
    <div class="strength">
      {{$t('UserProfile.Password strength')}}<strong ref="strength" id="strength"></strong>
    </div>
    <div class="suggestion">
      {{$t('UserProfile.Password Change Notice')}}
    </div>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn';
// 半角英大文字、半角英小文字、半角英数字、記号（(!@#$%^&*)）いずれか3種類以上を含み、9文字以上32文字まで
const regex =
  // eslint-disable-next-line no-useless-escape
  /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\(!@#$%^&*\)])|(?=.*[A-Z])(?=.*[0-9])(?=.*[\(!@#$%^&*\)])|(?=.*[a-z])(?=.*[0-9])(?=.*[\(!@#$%^&*\)]))([a-zA-Z0-9\(!@#$%^&*\)]){9,32}$/;

// 強度表示用マップ
const STRENGTH = ['Very Weak', 'Weak', 'Good', 'Strong', 'Very strong'];

export default {
  name: 'TwPasswordIndicator.vue',
  props: {
    current: String,
    input: String,
    input2: String,
    button: String
  },
  data() {
    return {
      $current: null,
      $input: null,
      $input2: null,
      $button: null,
      $indicator: null,
      $strength: null,
    }
  },
  mounted() {
    this.init();
    this.judge();
  },
  beforeDestroy() {
    this.$current.removeEventListener('keyup', this.judge);
    this.$input.removeEventListener('keyup', this.judge);
    this.$input2.removeEventListener('keyup', this.judge);
    this.$input.removeEventListener('paste', this.judge);
    this.$input2.removeEventListener('paste', this.judge);
  },
  methods: {
    init() {
      this.$current = document.getElementById(this.current) || {};
      this.$input = document.getElementById(this.input) || {};
      this.$input2 = document.getElementById(this.input2) || {};
      this.$button = document.getElementById(this.button) || {};
      this.$indicator = this.$refs.passwordIndicator;
      this.$strength = this.$refs.strength;
      this.$current.addEventListener('keyup', this.judge, false);
      this.$input.addEventListener('keyup', this.judge, false);
      this.$input2.addEventListener('keyup', this.judge, false);
      this.$input.addEventListener('paste', this.judge, false);
      this.$input2.addEventListener('paste', this.judge, false);
    },
    judge() {
      if (!this.$input.value.length) {
        this.$indicator.className = '';
        this.$strength.innerText = this.$t('UserProfile.Too short');
        this.$button.disabled = true;
        return;
      }
      const result = zxcvbn(this.$input.value);
      const score = regex.test(this.$input.value)
        ? Math.max(result.score, 2)
        : Math.min(result.score, 1);

      this.$indicator.className = `lv${score + 1}`;
      this.$strength.innerText = this.$t(`UserProfile.${STRENGTH[score]}`);

      if (!this.$current.value.length || !this.$input2.value.length || this.$input.value !== this.$input2.value || score < 2) {
        this.$button.disabled = true;
      } else {
        this.$button.disabled = false;
      }
    }
  }
}
</script>

<style>
  #password-indicator {
    width: 100%;
  }
  
  #password-indicator .gauge {
    width: 100%;
    height: 2px;
    background: url(../../assets/images/password_indicator.png) no-repeat;
    background-size: 100% 34px;
    margin-bottom: 4px;
  }
  
  #password-indicator .strength {
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
    color: #9191A4;
    padding-left: 2px;
    margin-bottom: 24px;
  }
  
  #password-indicator strong {
    margin-left: 12px;
    font-weight: bold;
    color: #3E3A39;
  }
  
  #password-indicator.lv1 .gauge {
    background-position: 0 -6px;
  }
  
  #password-indicator.lv2 .gauge {
    background-position: 0 -12px;
  }
  
  #password-indicator.lv3 .gauge {
    background-position: 0 -18px;
  }
  
  #password-indicator.lv4 .gauge {
    background-position: 0 -24px;
  }
  
  #password-indicator.lv5 .gauge {
    background-position: 0 100%;
  }
  
  #password-indicator .suggestion {
    background: #F9F9FD;
    border-radius: 6px;
    padding: 19px 24px 18px;
    font-size: 12px;
    line-height: 20px;
    vertical-align: middle;
  }
  
  #password-indicator.lv3 .suggestion, #password-indicator.lv4 .suggestion, #password-indicator.lv5 .suggestion {
    display: none;
  }
</style>
