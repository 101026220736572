<template>
  <el-dialog :title="$t('UserProfile.Edit Password')" :visible="visible" :close-on-click-modal="false" class="header_border" width="618px" @update:visible="$emit('update:visible', $event)">
    <el-form>
      <div class="form-group">
        <label for="current_password" class="control-label">{{$t('UserProfile.Current Password')}}</label>
        <div class="input-wrap">
          <input id="current_password" ref="currentPassword" class="form-control" name="current_password" type="password" placeholder="Password" autofocus autocomplete="off" maxlength="32" />
          <i class="eye" @click="togglePassword('current_password')"></i>
        </div>
      </div>
      <div class="form-group">
        <label for="password" class="control-label">{{$t('UserProfile.New Password')}}</label>
        <div class="input-wrap">
          <input id="password" ref="newPassword" class="form-control" name="password" type="password" placeholder="Password" autofocus autocomplete="off" maxlength="32" />
          <i class="eye" @click="togglePassword('password')"></i>
        </div>
      </div>
      <div class="form-group">
        <!-- パスワード強度 -->
        <tw-password-indicator current="current_password" input="password" input2="password2" button="kc-login"></tw-password-indicator>
        <!-- パスワード強度 -->
      </div>

      <div class="form-group">
        <label for="password2" class="control-label" v-html="sanitize($t('UserProfile.Password Confirmation'))" />
        <div class="input-wrap">
          <input id="password2" ref="newPasswordConfirm" class="form-control" name="password2" value="" type="password" placeholder="Password" autocomplete="off" maxlength="32" />
          <i class="eye" @click="togglePassword('password2')"></i>
        </div>
      </div>

      <div style="display: flex;justify-content: flex-end">
        <tw-button id="kc-login" type="primary" size="medium" @click="postPassword">{{$t('Button.OK')}}</tw-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import TwButton from '@/components/atoms/TwButton';
import TwPasswordIndicator from '@/components/molecules/TwPasswordIndicator';

export default {
  components: {
    TwButton,
    TwPasswordIndicator
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    togglePassword(id) {
      const $input = document.getElementById(id);
      if ($input.getAttribute('type') === 'password') {
        $input.setAttribute('type', 'text');
      } else {
        $input.setAttribute('type', 'password');
      }
    },
    postPassword() {
      const passwords = {
        new: '',
        confirm: '',
        current: ''
      }
      passwords.new = this.$refs.newPassword.value;
      passwords.confirm = this.$refs.newPasswordConfirm.value;
      passwords.current = this.$refs.currentPassword.value;
      this.$emit('postPassword', passwords)
    }
  }
}
</script>

<style lang="scss" scoped>
/* フォーム */
.form-group {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.form-group .control-label {
  color: $color_gray_800;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.form-group .form-control {
  width: 100%;
  height: 56px;
  font-size: 14px;
  line-height: 20px;
  padding: 18px 44px;
  background: #FFFFFF;
  border: 1px solid #EDEDF4!important;
  border-radius: 6px!important;
  box-sizing: border-box;
  box-shadow: none;
}

.form-group .form-control:focus {
  border: 1px solid #3EC1FF!important;
  box-shadow: inset 2.5px 2.5px 5px rgba(170, 170, 204, 0.5);
}

.form-group .form-control::placeholder {
  color: #9191A4;
  opacity: 1;
  font-style: normal!important;
  font-size: 14px;
  line-height: 20px;
}

#current_password, #password, #password2 {
  background: url(../../assets/images/icon_lock.png) no-repeat 12px center;
  background-size: 24px auto;
}

.form-group.error .form-control, .form-group.error .form-control:focus {
  border: 1px solid #E00001!important;
}

.input-wrap {
  position: relative;
}

i.eye {
  position: absolute;
  top: 18px;
  right: 8px;
  width: 40px;
  height: 20px;
  background: url(../../assets/images/icon_eye.png) no-repeat 0 0;
  background-size: 20px auto;
  cursor: pointer;
}

#current_password[type=text] + i.eye, #password[type=text] + i.eye, #password2[type=text] + i.eye {
  background-position: 0 bottom;
}

i.eye:active {
  opacity: .6;
}
</style>
