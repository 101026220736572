<template>
  <div class='tw_step_item'>
    <img class='circle_chevron' src='@/assets/images/icons/icon_circle_chevron.svg' alt=''>

    <div v-if='title' class='title'>
      {{ title }}
    </div>

    <div class='box_wrapper'>
      <span v-if='isShowBorder' class='dash_line' />

      <div class='box-inner' :style="{'max-height': maxHeight, 'overflow-y': maxHeight ? 'auto' : 'visible'}">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwStepItem',
  props: {
    title: {
      type: String,
      required: false
    },
    isShowBorder: {
      type: Boolean,
      required: false,
      default: true
    },
    maxHeight: {
      type: String,
      required: false,
    }
  }
};
</script>

<style scoped lang='scss'>
.tw_step_item {
  padding-left: 32px;
  position: relative;

  .circle_chevron {
    position: absolute;
    top: 6px;
    left: 0;
    z-index: 1;
  }

  .title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.75;
  }

  .box_wrapper {
    position: relative;

    .dash_line {
      border-left: 1px dashed $color_gray_400;
      position: absolute;
      // topの8px+したのboxとのgap16pxを足して線を伸ばす
      height: calc(8px + 16px + 100%);
      top: -8px;
      left: -24px;
    }

    .box-inner {
      background: $color_gray_100;
      padding: 20px 24px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 6px;
    }
  }
}
</style>
