<template>
  <el-dialog :title="$t('UserProfile.Language Setting')" :visible="visible" :close-on-click-modal="false" class="header_border" width="500px" @update:visible="$emit('update:visible', $event)" @close="$emit('dialogCloseWithoutSave')">
    <div ref="languageModal" class="languages-wrapper">
      <el-radio-group :value="language" @input="$emit('update:language', $event)">
        <el-radio v-for="language in languages" style="display: block;margin-bottom: 16px;" :key="language.code" :label="language.code" @change="$emit('onLocaleChange', $event)">{{language.label}}</el-radio>
      </el-radio-group>
    </div>
    <div style="display: flex;justify-content: flex-end;padding-top: 12px">
      <tw-button type="primary" @click="$emit('postLanguage')">{{$t('Button.OK')}}</tw-button>
    </div>
  </el-dialog>
</template>

<script>
import TwButton from '@/components/atoms/TwButton';

export default {
  components: {
    TwButton
  },
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    language: {
      type: Number,
      required: false,
      default: null
    },
    languages: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.$nextTick(() => {
          // TAB押下でチェックボックスにフォーカスさせるためにモーダルにフォーカス
          this.$refs.languageModal.focus();
        });
      }
    }
  }
}
</script>
