<template>
  <div class="tw-filterable-multi-select">
    <div v-if="needOutsideTags" class="selected-tags-wrapper">
      <el-tag
        v-for="val in values"
        :key="val[keyForKey]"
        type="info"
        closable
        disable-transitions
        @close="unSelect(val)"
      >
        {{ val[keyForLabel] }}
      </el-tag>
    </div>
    <el-select
      ref="elSelect"
      :value="selectVal"
      :value-key="keyForKey"
      :placeholder="placeholder"
      multiple
      filterable
      :popper-append-to-body="false"
      popper-class="tw-filterable-multi-select-options"
      @remove-tag="unSelect($event)"
      @click.native.once="startElSelectWatch"
    >
      <div
        v-show="needSelectAll"
        :class="`el-select-dropdown__item ${allSelected ? 'selected' : '' }`"
        @click="toggleAllSelect"
      >
        {{$t('Common.Select All')}}
      </div>
      <el-option
        :class="{ selected: option[keyForVal] === trueVal }"
        v-for="(option, i) in options"
        :value="option"
        :key="option[keyForKey]"
        :label="option[keyForLabel]"
        @click.native="update(i)"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    keyForKey: {
      type: String,
      required: false,
      default: 'key'
    },
    keyForVal: {
      type: String,
      required: false,
      default:  'value'
    },
    keyForLabel: {
      type: String,
      required: false,
      default: 'label'
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select'
    },
    trueVal: {
      type: [String, Number, Boolean],
      required: false,
      default: 1
    },
    falseVal: {
      type: [String, Number, Boolean],
      required: false,
      default: 0
    },
    tagsInSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    needOutsideTags: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      cloneOptions: [],
      needSelectAll: true,
      filteredOptionKeys: []
    }
  },
  computed: {
    values() {
        return this.cloneOptions.filter(option => option[this.keyForVal] === this.trueVal);
    },
    selectVal() {
      return this.tagsInSelect ? this.values : [];
    },
    allSelected() {
      const selectedValueKeys = this.values.map((val) => val[this.keyForKey]);
      return this.filteredOptionKeys.every((key) => selectedValueKeys.includes(key));
    }
  },
  watch: {
    options: {
      handler(options) {
        this.cloneOptions = [..._.cloneDeep(options)];
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    
  },
  methods: {
    // filteringをかけた場合の「すべて選択」の動作を正しく行わせるためにel-select内のデータをwatchする
    startElSelectWatch() {
      this.$watch('$refs.elSelect.filteredOptionsCount', (val) => {
        this.needSelectAll = val > 0;
        this.filteredOptionKeys = [...this.$refs.elSelect.options.filter(option => {
          return option.visible
        }).map(option => option.value[this.keyForKey])];
      }, {
        immediate: true
      });
    },
    update(optionIndex) {
      const targetVal = this.cloneOptions[optionIndex][this.keyForVal] === this.trueVal ? this.falseVal : this.trueVal;
      this.cloneOptions[optionIndex][this.keyForVal] = targetVal
      this.$emit('update:options', this.cloneOptions);
      this.$emit('update:value', this.values);
    },
    unSelect(val) {
      const optionIndex = this.cloneOptions.findIndex(option => option[this.keyForKey] === val[this.keyForKey]);
      this.cloneOptions[optionIndex][this.keyForVal] = this.falseVal;
      this.$emit('update:options', this.cloneOptions);
      this.$emit('update:value', this.values);
    },
    toggleAllSelect() {
      const targetVal = this.allSelected ? this.falseVal : this.trueVal;
      this.cloneOptions = [...this.cloneOptions.map(option => {
        const val = this.filteredOptionKeys.includes(option[this.keyForKey]) ? targetVal : option[this.keyForVal];
        return {
          ...option,
          [this.keyForVal]: val
        }
      })];
      this.$emit('update:options', this.cloneOptions);
      this.$emit('update:value', this.values);
    }
  } 
}
</script>

<style lang="scss" scoped>
.tw-filterable-multi-select {
  width: 100%;

  & .selected-tags-wrapper {
    margin-bottom: 8px;

    & .el-tag {
      color: $color_gray_800;
      background-color: $color_gray_300;
      line-height: 28px;
      margin: 4px 4px 0 0;
      white-space: normal;
      overflow-wrap: anywhere;
      overflow: visible;
      text-overflow: unset;
      max-width: calc(100% - 24px);
      // Element UIのheightのスタイルをmin-heightで上書き
      min-height: 32px;
      height: auto;
      position: relative;
      padding-right: 24px;

      &::v-deep .el-tag__close {
        color: #9191A4;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 6px;
      }
    } 
  }

  & .el-select {
    width: 100%;

    & ::v-deep .el-input input {
      border-color: rgba($color_dark_blue, 0.5);
    }

    & ::v-deep .el-select-dropdown.tw-filterable-multi-select-options {
      max-width: 100%;
      // select上のタグ数に応じてselectの位置が下がってもdropdownの位置が変わらない & モーダル上からSelectが消えたらdropdownも見えなく、実現のためfixedからabsoluteへ変更
      position: absolute !important;
      top: 40px !important;
      left: 0 !important;
      // positionをabsoluteにしたため、要素の下に入っているのか、上にあるのか境目がややこしくなる対策としてbox-shadowの代わりにborderをつける
      border-right: 1px solid rgba(170, 170, 204, 0.5);
      border-left: 1px solid rgba(170, 170, 204, 0.5);
      border-bottom: 1px solid rgba(170, 170, 204, 0.5);
      box-shadow: 0px 3px 7px rgba(170, 170, 204, 0.5);


      & .el-select-dropdown__item {
        white-space: normal;
        overflow-wrap: anywhere;
        overflow: visible;
        text-overflow: unset;
        // Element UIのheightのスタイルをmin-heightで上書き
        min-height: 34px;
        height: auto;
      }

      &.is-multiple .el-select-dropdown__item.selected::after {
        top: 50%;
        transform: translate(0, -50%);
      }

    }
  }
}
</style>
