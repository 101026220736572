<template>
  <el-dialog
    :title="$t('UserProfile.Notifications')"
    :visible='visible'
    :close-on-click-modal='false'
    class='header_border notification-dialog'
    width='880px'
    @update:visible="$emit('update:visible', $event)"
  >
    <div class='notification-settings' ref='notificationModal'>
      <tw-step-item :title="`${$t('UserProfile.Notification Conditions')} :`">
        <div class='condition'>
          <div class='target-explanation'>{{ $t('UserProfile.Process Actions') }}</div>

          <div class='process-notification-wrapper'>
            <el-checkbox
              :indeterminate='!checkedAllProcess && newNotificationProcessFlg === 1'
              v-model='checkedAllProcess'
            >
              {{ $t('Common.Select All') }}
            </el-checkbox>

            <div class='individual-process-notification-wrapper'>
              <div class='setting-with-explanation is-process'>
                <el-checkbox
                  :value='notificationApprovalRequestFlg'
                  :true-label='1'
                  :false-label='0'
                  @input="$emit('update:notificationApprovalRequestFlg', $event)"
                >
                  {{ $t('UserProfile.Approval Request') }}
                </el-checkbox>

                <div class='item-explanation'>{{ $t('UserProfile.Approval Request Description') }}</div>
              </div>

              <div class='setting-with-explanation is-process'>
                <el-checkbox
                  :value='notificationApprovalNgFlg'
                  :true-label='1'
                  :false-label='0'
                  @input="$emit('update:notificationApprovalNgFlg', $event)"
                >
                  {{ $t('UserProfile.Return') }}
                </el-checkbox>

                <div class='item-explanation'>{{ $t('UserProfile.Return Description') }}</div>
              </div>

              <div class='setting-with-explanation is-process'>
                <el-checkbox
                  :value='notificationApprovedFlg'
                  :true-label='1'
                  :false-label='0'
                  @input="$emit('update:notificationApprovedFlg', $event)"
                >
                  {{ $t('UserProfile.Approved') }}
                </el-checkbox>

                <div class='item-explanation'>{{ $t('UserProfile.Approved Description') }}</div>
              </div>

              <div class='setting-with-explanation is-process'>
                <el-checkbox
                  :value='notificationConfirmationRequestFlg'
                  :true-label='1'
                  :false-label='0'
                  @input="$emit('update:notificationConfirmationRequestFlg', $event)"
                >
                  {{ $t('UserProfile.Confirmation Request') }}
                </el-checkbox>

                <div class='item-explanation'>{{ $t('UserProfile.Confirmation Request Description') }}</div>
              </div>

              <div class='setting-with-explanation is-process'>
                <el-checkbox
                  :value='notificationDoneFlg'
                  :true-label='1'
                  :false-label='0'
                  @input="$emit('update:notificationDoneFlg', $event)"
                >
                  {{ $t('UserProfile.Done') }}
                </el-checkbox>

                <div class='item-explanation'>{{ $t('UserProfile.Done Description') }}</div>
              </div>

              <div class='setting-with-explanation is-process'>
                <el-checkbox
                  :value='notificationWithdrawedFlg'
                  :true-label='1'
                  :false-label='0'
                  @input="$emit('update:notificationWithdrawedFlg', $event)"
                >
                  {{ $t('UserProfile.Withdraw') }}
                </el-checkbox>

                <div class='item-explanation'>{{ $t('UserProfile.Withdraw Description') }}</div>
              </div>
            </div>
          </div>

          <div class='chat-notification-wrapper'>
            <div class='target-explanation'>{{ $t('UserProfile.Chat') }}</div>

            <div class='setting-with-explanation is-mention'>
              <el-checkbox
                :value='notificationMentionFlg'
                :true-label='1'
                :false-label='0'
                @input="$emit('update:notificationMentionFlg', $event)"
              >
                {{ $t('UserProfile.Mention Received') }}
              </el-checkbox>

              <div class='item-explanation'>{{ $t('UserProfile.Mention Description') }}</div>
            </div>
          </div>
        </div>
      </tw-step-item>

      <tw-step-item :title="`${$t('UserProfile.Target Sections')} :`">
        <div class='section'>
          <tw-filterable-multi-select-with-select-all
            :options='sectionNotificationSettings'
            key-for-key='sectionId'
            key-for-val='notificationEnabledType'
            key-for-label='sectionShortName'
            placeholder='Select'
            @update:options="$emit('update:sectionNotificationSettings', $event)"
          />
        </div>
      </tw-step-item>

      <tw-step-item :title="`${$t('UserProfile.Notification Via')} :`" :isShowBorder='false'>
        <div class='destination'>
          <el-checkbox :value='true' disabled class='disabled_checkbox'>
            {{ $t('UserProfile.TradeWaltz App') }}
          </el-checkbox>

          <el-checkbox
            :value='notificationMailFlg'
            :true-label='1'
            :false-label='0'
            @input="$emit('update:notificationMailFlg', $event)"
          >
            {{ $t('UserProfile.Email') }}
          </el-checkbox>

          <!-- <el-checkbox v-model="cloneProfile.notificationDailyReportFlg" :true-label="1" :false-label="0">{{$t('UserProfile.Receive Daily Report')}}</el-checkbox> -->
        </div>
      </tw-step-item>
    </div>

    <div slot='footer' style='display: flex;justify-content: flex-end;padding-top: 12px'>
      <tw-button type='primary' @click="$emit('postNotification')">{{ $t('Button.OK') }}</tw-button>
    </div>
  </el-dialog>
</template>

<script>
import TwStepItem from '@/components/atoms/TwStepItem.vue';
import TwFilterableMultiSelectWithSelectAll from '@/components/molecules/TwFilterableMultiSelectWithSelectAll.vue';
import TwButton from '@/components/atoms/TwButton';

export default {
  components: {
    TwStepItem,
    TwFilterableMultiSelectWithSelectAll,
    TwButton
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    notificationMailFlg: {
      type: Number,
      required: false,
      default: 0
    },
    notificationMentionFlg: {
      type: Number,
      required: false,
      default: 0
    },
    processNotificationKeys: {
      type: Array,
      required: false,
      default: () => []
    },
    newNotificationProcessFlg: {
      type: Number,
      required: false,
      default: 0
    },
    notificationApprovalRequestFlg: {
      type: Number,
      required: false,
      default: 0
    },
    notificationApprovalNgFlg: {
      type: Number,
      required: true,
      default: 0
    },
    notificationApprovedFlg: {
      type: Number,
      required: false,
      default: 0
    },
    notificationConfirmationRequestFlg: {
      type: Number,
      required: false,
      default: 0
    },
    notificationDoneFlg: {
      type: Number,
      required: false,
      default: 0
    },
    notificationWithdrawedFlg: {
      type: Number,
      required: false,
      default: 0
    },
    sectionNotificationSettings: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          // TAB押下でチェックボックスにフォーカスさせるためにモーダルにフォーカス
          this.$refs.notificationModal.focus();
        });
      }
    }
  },
  computed: {
    checkedAllProcess: {
      get() {
        return this.processNotificationKeys.every(key => this[key] === 1);
      },
      set(val) {
        const newFlgVal = val ? 1 : 0;
        for (const key of this.processNotificationKeys) {
          this.$emit(`update:${key}`, newFlgVal);
        }
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.notification-settings {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .condition {
    .process-notification-wrapper {
      margin-bottom: 16px;
    }
  }

  .process-notification-wrapper, .destination {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .process-notification-wrapper .individual-process-notification-wrapper {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .target-explanation {
    color: $color_gray_800;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.7;
    margin-bottom: 12px;
  }

  .item-explanation {
    word-break: break-word;
    color: $color_gray_800;
    padding-left: 24px;
    margin-top: 4px;
    font-size: 14px;
    line-height: 1.5;
  }
}

::v-deep .el-checkbox__label {
  font-weight: normal;
}

// TODO: header, footer固定のスタイルを共通化したい
.el-dialog__wrapper {
  &.notification-dialog {
    ::v-deep .el-dialog {
      height: 80vh;

      & .el-dialog__body {
        overflow-y: auto;
        // header 60px, footer 85pxとして、bodyの高さを計算
        height: calc(100% - 60px - 85px)
      }
    }
  }
}
</style>
