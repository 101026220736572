<template>
  <div>
    <tw-header :title="$t('UserProfile.Profile')" />
    <div class='tw_container'>
      <tw-breadcrumbs v-if='breadcrumbs.length' :pages='breadcrumbs' />
      <div class='tw_user_profile'>
        <div class='user_profile_and_tabs'>
          <div class='user_profile_wrapper'>
            <div style='position: relative;'>
              <el-dropdown trigger='click' placement='bottom-start' @command='onDropDownClick' :tabindex='-1'>
                <div class='el-dropdown-link user_icon'>
                  <figure>
                    <el-skeleton animated :loading='isLoading' style='height:100%;'>
                      <template #template>
                        <el-skeleton-item variant='image' style='height:73px;' />
                      </template>

                      <img :src='profile.userIcon' v-default-src="'user'" alt=''>
                    </el-skeleton>
                  </figure>
                </div>

                <el-dropdown-menu>
                  <el-dropdown-item command='openFile'>{{ $t('UserProfile.Choose Photo') }}</el-dropdown-item>

                  <el-dropdown-item v-if='profile.userIcon' command='deleteIcon'>
                    {{ $t('UserProfile.Delete profile icon') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <input ref='fileInput' class='file_input' @change='setImage' type='file' name='image'
                     accept='.jpg,.gif,.png,image/gif,image/jpeg,image/png' tabindex='-1'>
            </div>

            <el-skeleton animated :loading='isLoading' variant='h3' :rows='3'>
              <div class='user_profile'>
                <div class='name'>{{ profile.userName }}</div>

                <div class='email'>{{ profile.emailAddress }}</div>
              </div>
            </el-skeleton>

          </div>

          <el-menu class='tabs' default-active='setting' @select='selectTab'>
            <el-menu-item index='setting'>
              <img src='@/assets/images/icons/icon_account_setting.svg' alt=''>
              {{ $t('UserProfile.Settings') }}
            </el-menu-item>

            <el-menu-item index='organization'>
              <img src='@/assets/images/icons/icon_building.svg' alt=''>
              {{ $t('UserProfile.Organization') }}
            </el-menu-item>
          </el-menu>
        </div>

        <hr>

        <div v-show='activeTab === "setting"' class='user_settings'>
          <div class='user_settings_row'>
            <div class='user_setting language'>
              <div class='label'>
                {{ $t('UserProfile.Language') }}
                <img class='edit_button' src='@/assets/images/icons/icon_edit.svg' @click='showLanguageModal' alt=''>
              </div>

              <div class='display_value'>
                <el-skeleton animated :loading='isLoading'>
                  <template #template>
                    <el-skeleton-item />
                  </template>

                  <template>
                    {{ getLanguageLabel }}
                  </template>
                </el-skeleton>
              </div>
            </div>

            <div class='user_setting password'>
              <div class='label'>
                {{ $t('UserProfile.Password') }}
                <img class='edit_button' src='@/assets/images/icons/icon_edit.svg' @click='showPasswordModal' alt=''>
              </div>

              <div class='display_value'>
                <el-skeleton animated :loading='isLoading'>
                  <template #template>
                    <el-skeleton-item />
                  </template>

                  <template>
                    <span>**********</span>
                  </template>
                </el-skeleton>
              </div>
            </div>
          </div>

          <hr>

          <div class='user_setting'>
            <div class='label'>
              {{ $t('UserProfile.Notifications') }}
              <img class='edit_button' src='@/assets/images/icons/icon_edit.svg' @click='showNotificationModal' alt=''>
            </div>

            <el-skeleton animated :rows='5' :loading='isLoading'>
              <div class='notification_wrapper'>
                <TwStepItem :title="`${$t('UserProfile.Notification Conditions')} :`">
                  <div class='notification_setting'>
                    <div>
                      <p class='sub_title'>{{ $t('UserProfile.Process Actions') }}</p>

                      <div class='display_value'>
                        {{ notificationProcessActionsDisplayValue }}
                      </div>
                    </div>

                    <div>
                      <p class='sub_title'>{{ $t('UserProfile.Chat') }}</p>

                      <div class='display_value'>
                        {{ notificationMentionDisplayValue }}
                      </div>

                      <div class='description'>{{ $t('UserProfile.Mention Description') }}</div>
                    </div>
                  </div>
                </TwStepItem>

                <TwStepItem :title="`${$t('UserProfile.Target Sections')} :`" max-height='260px'>
                  <div class='notification_setting display_value'>
                    {{ notificationSectionsDisplayValue }}
                  </div>
                </TwStepItem>

                <TwStepItem :title="`${$t('UserProfile.Notification Via')} :`" :isShowBorder='false'>
                  <div class='notification_setting display_value'>
                    {{ notificationViaDisplayValue }}
                  </div>
                </TwStepItem>
              </div>
            </el-skeleton>
          </div>
        </div>

        <div v-show='activeTab === "organization"' class='user_settings'>
          <el-skeleton animated :rows='5' :loading='isLoading'>
            <div class='organizations'>
              <div v-for='userAssign in profile.userAssigns' :key='userAssign.organizationId'>
                {{ userAssign[localeLanguage].organizationName }}{{ getUserRoleLabel(userAssign.userRoleCd) }}
              </div>
            </div>
          </el-skeleton>
        </div>
      </div>
    </div>

    <tw-user-profile-image-dialog
      :visible.sync='dialogTrimVisible'
      :img-src='imgSrc'
      @changeIcon='changeUserIcon'
    />

    <tw-user-profile-notification-dialog
      :visible.sync='dialogNotificationVisible'
      :notification-mail-flg.sync='cloneProfile.notificationMailFlg'
      :notification-mention-flg.sync='cloneProfile.notificationMentionFlg'
      :process-notification-keys='processNotificationKeys'
      :new-notification-process-flg='newNotificationProcessFlg'
      :notification-approval-request-flg.sync='cloneProfile.notificationApprovalRequestFlg'
      :notification-approval-ng-flg.sync='cloneProfile.notificationApprovalNgFlg'
      :notification-approved-flg.sync='cloneProfile.notificationApprovedFlg'
      :notification-confirmation-request-flg.sync='cloneProfile.notificationConfirmationRequestFlg'
      :notification-done-flg.sync='cloneProfile.notificationDoneFlg'
      :notification-withdrawed-flg.sync='cloneProfile.notificationWithdrawedFlg'
      :section-notification-settings.sync='cloneSectionNotificationSettings'
      @postNotification='postNotification'
    />

    <tw-user-profile-password-dialog
      :visible.sync='dialogPasswordVisible'
      @postPassword='postPassword'
    />

    <tw-user-profile-language-dialog
      :visible.sync='dialogLanguageVisible'
      :language.sync='cloneProfile.language'
      :languages='languages'
      @dialogCloseWithoutSave='onLanguageDialogClose'
      @onLocaleChange='onLocaleChange'
      @postLanguage='postLanguage'
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import TwUserProfileImageDialog from '@/components/organisms/TwUserProfileImageDialog.vue';
import { USER_ROLE_CD_VARIABLES_OPTIONS, ICON_EDIT_CATEGORY, LANGUAGE_SETTING } from 'lib-tw-common';
import TwHeader from '@/components/organisms/TwHeader';
import TwBreadcrumbs from '@/components/molecules/TwBreadcrumbs';
import mixinLanguage from '@/utils/mixinLanguage.js';
import TwUserProfileNotificationDialog from '@/components/organisms/TwUserProfileNotificationDialog.vue';
import TwUserProfilePasswordDialog from '@/components/organisms/TwUserProfilePasswordDialog.vue';
import TwUserProfileLanguageDialog from '@/components/organisms/TwUserProfileLanguageDialog.vue';
import TwStepItem from '@/components/atoms/TwStepItem';

const WHITE_LIST = ['jpg', 'jpeg', 'png', 'gif'];
// 拡張子を取得します
const getFileExtension = filename => {
  const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  return extension.toLowerCase();
};

export default {
  name: 'UserProfile',
  mixins: [mixinLanguage],
  props: {
    noticeHistoryId: String
  },
  components: {
    TwHeader,
    TwBreadcrumbs,
    TwUserProfileImageDialog,
    TwUserProfileNotificationDialog,
    TwUserProfilePasswordDialog,
    TwUserProfileLanguageDialog,
    TwStepItem
  },
  data() {
    return {
      dialogNotificationVisible: false,
      dialogTrimVisible: false,
      dialogPasswordVisible: false,
      dialogLanguageVisible: false,
      imgSrc: '',
      cloneProfile: {},
      cloneSectionNotificationSettings: [],
      language: '',
      processNotificationKeys: [
        'notificationApprovalRequestFlg',
        'notificationApprovalNgFlg',
        'notificationApprovedFlg',
        'notificationConfirmationRequestFlg',
        'notificationDoneFlg',
        'notificationWithdrawedFlg'
      ],
      activeTab: 'setting',
      isLoading: false
    };
  },
  computed: {
    breadcrumbs() {
      return [{ label: this.$t('BreadCrumbs.Dashboard'), to: '/' }, { label: this.$t('BreadCrumbs.Profile'), to: '' }];
    },
    getUserRoleLabel() {
      return code => {
        return ` (${_.get(_.find(USER_ROLE_CD_VARIABLES_OPTIONS, { code: code }), 'label')})`;
      };
    },
    companyId() {
      return this.$store.state.companyId;
    },
    profile() {
      return this.$store.state.userProfile;
    },
    sectionNotificationSettings() {
      return this.$store.state.sectionNotificationSettings;
    },
    // APIのnotificationProcessFlgに送る値（プロセスに関するチェックの有無を管理していると同義）
    newNotificationProcessFlg() {
      if(Object.keys(this.cloneProfile).length === 0) return this.profile.notificationProcessFlg;
      for(const key of this.processNotificationKeys) {
        if(this.cloneProfile[key] === 1) return 1;
      }
      return 0;
    },
    lslConfig() {
      // bff_pf_2 ユーザープロフィール更新BFF
      return {
        serviceCode: 'tw-transaction-bff-api',
        apiCode: 'post_/v1/profile/{companyId}',
        path: {
          companyId: this.companyId
        }
      };
    },
    lslConfigForSectionNotification() {
      return {
        serviceCode: 'tw-user-entity-bff-api',
        apiCode: 'post_/v1/user-settings/notification-settings/section'
      };
    },
    getLanguageLabel() {
      return _.get(_.find(this.languages, {code: this.language}), 'label') || '';
    },
    localeLanguage() {
      return this.language === LANGUAGE_SETTING.ENGLISH ? 'en' : 'local';
    },
    notificationProcessActionsDisplayValue() {
      const selectedProcessActions = [];

      if (this.profile.notificationApprovalRequestFlg) selectedProcessActions.push(this.$t('UserProfile.Approval Request'));
      if (this.profile.notificationApprovalNgFlg) selectedProcessActions.push(this.$t('UserProfile.Return'));
      if (this.profile.notificationApprovedFlg) selectedProcessActions.push(this.$t('UserProfile.Approved'));
      if (this.profile.notificationConfirmationRequestFlg) selectedProcessActions.push(this.$t('UserProfile.Confirmation Request'));
      if (this.profile.notificationDoneFlg) selectedProcessActions.push(this.$t('UserProfile.Done'));
      if (this.profile.notificationWithdrawedFlg) selectedProcessActions.push(this.$t('UserProfile.Withdraw'));

      return selectedProcessActions.length ? selectedProcessActions.join(' | ') : this.$t('Common.Not Selected');
    },
    notificationMentionDisplayValue() {
      return `${this.$t('UserProfile.Mention Received')} - ${this.profile.notificationMentionFlg ? this.$t('Common.On') : this.$t('Common.Off')}`;
    },
    notificationSectionsDisplayValue() {
      const enabledSection = [];

      this.sectionNotificationSettings.forEach(section => {
        if (section.notificationEnabledType === 1) enabledSection.push(section.sectionShortName);
      });

      return enabledSection.length ? enabledSection.join(' | ') : this.$t('Common.Not Selected');
    },
    notificationViaDisplayValue() {
      const via = [this.$t('UserProfile.TradeWaltz App')];

      if (this.profile.notificationMailFlg) via.push(this.$t('UserProfile.Email'));
      return via.join(' | ');
    }
  },
  watch: {
    companyId() {
      this.fetch();
    }
  },
  created() {
    this.language = this.$store.state.language;
    this.fetch();
  },
  methods: {
    fetch(companyId = this.companyId) {
      if (companyId) {
        this.isLoading = true;

        this.$store.dispatch('GET_USER_PROFILE')
          .then(this.setLanguage)
          .catch(() => {
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      this.$store.dispatch('GET_USER_NOTIFICATION_SETTINGS');
    },
    selectTab(activeTab) {
      this.activeTab = activeTab;
    },
    showNotificationModal() {
      this.cloneProfile = _.cloneDeep(this.profile);
      this.cloneSectionNotificationSettings = [..._.cloneDeep(this.sectionNotificationSettings)];
      this.dialogNotificationVisible = true;
    },
    showPasswordModal() {
      this.dialogPasswordVisible = true;
    },
    showLanguageModal() {
      this.cloneProfile = _.cloneDeep(this.profile);
      this.dialogLanguageVisible = true;
    },
    onDropDownClick(command) {
      if (command === 'openFile') {
        this.$refs.fileInput.click();
      } else if (command === 'deleteIcon') {
        this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to delete this data?`)
          .then(() => {
            this.deleteUserIcon();
          })
          .catch(() => {});
      }
    },
    setImage(e) {
      const file = e.target.files[0];
      if (!WHITE_LIST.includes(getFileExtension(file.name))) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        this.imgSrc = '';
        const reader = new FileReader();
        reader.onload = event => {
          this.imgSrc = event.target.result;
          e.target.value = '';
          this.dialogTrimVisible = true;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    changeUserIcon(newIcon) {
      this.$store.commit('START_PROCESS');
      const oldIcon = this.profile.userIcon;
      this.profile.userIcon = newIcon;
      const params = {
        lslConfig: this.lslConfig,
        data: {
          iconEditCategory: ICON_EDIT_CATEGORY.CHANGE,
          validityStartDate: this.profile.validityStartDate,
          userIcon: newIcon,
          updateDateBk: this.profile.updateDate,
        }
      };

      $api.request(params)
      .then(res => {
        this.$store.commit('SET_USER_PROFILE', res.users[0]);
        this.$store.commit('END_PROCESS');
        this.dialogTrimVisible = false;
        setTimeout(() => {
          this.$store.dispatch('SHOW_COMPLETED');
        }, 500);
      })
      .catch(err => {
        this.profile.userIcon = oldIcon;
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    deleteUserIcon() {
      this.$store.commit('START_PROCESS');
      const params = {
        lslConfig: this.lslConfig,
        data: {
          iconEditCategory: ICON_EDIT_CATEGORY.DELETE,
          validityStartDate: this.profile.validityStartDate,
          updateDateBk: this.profile.updateDate,
        }
      };

      $api.request(params)
      .then(res => {
        this.$store.commit('SET_USER_PROFILE', res.users[0]);
        this.$store.commit('END_PROCESS');
        setTimeout(() => {
          this.$store.dispatch('SHOW_COMPLETED');
        }, 500);
      })
      .catch(err => {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    postNotification() {
      this.$store.commit('START_PROCESS');
      const paramsForSetting = {
        lslConfig: this.lslConfig,
        data: {
          iconEditCategory: ICON_EDIT_CATEGORY.MAINTAIN,
          validityStartDate: this.cloneProfile.validityStartDate,
          updateDateBk: this.cloneProfile.updateDate,
          notificationProcessFlg: this.newNotificationProcessFlg,
          notificationApprovalRequestFlg: this.cloneProfile.notificationApprovalRequestFlg,
          notificationApprovalNgFlg: this.cloneProfile.notificationApprovalNgFlg,
          notificationApprovedFlg: this.cloneProfile.notificationApprovedFlg,
          notificationConfirmationRequestFlg: this.cloneProfile.notificationConfirmationRequestFlg,
          notificationDoneFlg: this.cloneProfile.notificationDoneFlg,
          notificationWithdrawedFlg: this.cloneProfile.notificationWithdrawedFlg,
          notificationMentionFlg: this.cloneProfile.notificationMentionFlg,
          notificationMailFlg: this.cloneProfile.notificationMailFlg,
        }
      };

      const sectionNotificationSettingList = this.cloneSectionNotificationSettings.map(setting => {
        return {
          sectionId: setting.sectionId,
          notificationEnabledType: setting.notificationEnabledType
        }
      })
      const paramsForSectionNotification = {
        lslConfig: this.lslConfigForSectionNotification,
        data: {
          sectionNotificationSettingList
        }
      }

      $api.request(paramsForSetting)
      .then(res => {
        this.$store.commit('SET_USER_PROFILE', res.users[0]);
        $api.request(paramsForSectionNotification)
        .then(res => {
          this.$store.commit('SET_SECTION_NOTIFICATION_SETTINGS', res.sectionNotificationSettingList);
          this.$store.commit('END_PROCESS');
          this.dialogNotificationVisible = false;
          setTimeout(() => {
            this.$store.dispatch('SHOW_COMPLETED');
          }, 500);
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        })
      })
      .catch(err => {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    postLanguage() {
      this.$store.commit('START_PROCESS');
      const params = {
        lslConfig: this.lslConfig,
        data: {
          iconEditCategory: ICON_EDIT_CATEGORY.MAINTAIN,
          validityStartDate: this.cloneProfile.validityStartDate,
          updateDateBk: this.cloneProfile.updateDate,
          language: this.cloneProfile.language,
          // TODO 言語設定項目
        }
      };

      $api.request(params)
      .then(res => {
        this.$store.commit('SET_USER_PROFILE', res.users[0]);
        this.$store.commit('END_PROCESS');
        this.dialogLanguageVisible = false;
        setTimeout(() => {
          this.$store.dispatch('SHOW_COMPLETED');
        }, 500);
      })
      .catch(err => {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    postPassword(passwords) {
      const params = {
        lslConfig: {
          serviceCode: 'lakeel-passport-api',
          apiCode: 'updatePassword',
          path: {
            // tw 固定
            userpoolId: 'tw'
          }
        },
        data: {
          // tw 固定
          clientId: 'tw',
          // ログインユーザID
          username: (this.profile.userId || '').toLowerCase(), //toLowerCase()して送信
          newPassword: passwords.new,
          newPasswordConfirm: passwords.confirm,
          currentPassword: passwords.current,
        }
      };

      $api.request(params)
      // eslint-disable-next-line no-unused-vars
      .then(res => {
        this.$store.commit('END_PROCESS');
        this.dialogPasswordVisible = false;
        setTimeout(() => {
          this.$store.dispatch('SHOW_COMPLETED');
        }, 500);
      })
      .catch(err => {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    onLocaleChange(language) {
      this.language = language;
      this.setLanguage(language);
    },
    onLanguageDialogClose() {
      this.language = this.profile.language;
      this.setLanguage(this.language);
    },
  }
};
</script>

<style lang='scss' scoped>
.tw_user_profile {
  display: flex;
  min-width: 900px;
  max-width: 1000px;
  min-height: 700px;
  margin: 0 auto;
  background: $color_white;
  border-radius: 12px;
  @include card_shadow;
  padding: 32px 40px;

  hr {
    width: 1px;
    background-color: $color_gray_300;
    border: none;
    margin: 0;
  }

  .user_profile_and_tabs {
    width: 280px;

    .user_profile_wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-right: 32px;


      .user_icon {
        position: relative;
        width: 73px;
        height: 73px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
          transition: transform .3s;
        }

        figure {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          overflow: hidden;
          margin: 0;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &:after {
          position: absolute;
          bottom: 1px;
          right: 1px;
          content: '';
          width: 20px;
          height: 20px;
          background: url(../assets/images/icons/user_icon_plus.png) no-repeat center;
          background-size: 20px auto;
        }
      }

      .file_input {
        position: absolute;
        width: 1px;
        height: 1px;
        z-index: -1;
        pointer-events: none;
      }
    }

    ::v-deep .user_profile {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .name {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.02em;
        line-height: 1.5;
      }

      .email {
        font-size: 14px;
        line-height: 1.5;
      }
    }

    .tabs {
      border: none;
      margin-top: 32px;
    }

    ::v-deep .tabs {

      .el-menu-item {
        padding-left: 12px !important;
        padding-right: 12px;
        height: 50px;
        line-height: 50px;

        img {
          margin-right: 8px;
        }

        &:hover:not(.is-active) {
          opacity: 0.8;
          background: inherit;
        }

        &.is-active {
          background: $color_gray_100;
          color: inherit;

          .svg {
            fill: red;
          }
        }
      }
    }
  }

  ::v-deep .display_value {
    line-height: 1.75;
  }

  .user_settings {
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    flex: 1;

    hr {
      height: 1px;
      width: 100%;
      background-color: $color_gray_300;
      border: none;
      margin: 0;
    }

    .label {
      color: $color_gray_800;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .edit_button {
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
        transition: transform .3s;
      }
    }

    ::v-deep .user_setting {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.language {
        min-width: 160px;
      }

      &.password {
        min-width: 160px;

        .display_value span {
          font-size: 12px;
          letter-spacing: 0.25em;
          width: 100%;
        }
      }

      .notification_wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .notification_setting {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .sub_title {
          color: $color_gray_800;;
          font-size: 12px;
          font-weight: 700;
          margin: 0 0 8px 0;
          line-height: 1.7;
        }

        .description {
          font-size: 14px;
          line-height: 1.5;
          margin-top: 4px;
          color: $color_gray_800;
        }
      }
    }

    .user_settings_row {
      display: flex;
      gap: 24px;
    }

    ::v-deep .organizations {
      display: flex;
      flex-direction: column;
      gap: 8px;
      line-height: 1.75;
    }
  }
}
</style>
